<template>

</template>
<script>
export default {
  name: "win",
  data: function() {
    return {
      number: undefined,
    };
  },
  created() {
      document.location = 'https://oxoformdocs.fra1.digitaloceanspaces.com/604bba5ec9ff400015f11f9f/politique.pdf'
  }
};
</script>
